<template>
  <!-- Terrible hack until component is ready in MDS V3 -->
  <label class="mds-label mds-label___radar">
    <div class="mds-label__text mds-label__text___radar">{{ label }}</div>
    <input
      ref="picker"
      v-bind="$attrs"
      :value="formattedValue"
      type="text"
      class="mds-date-picker mds-input mds-input___radar"
    >
  </label>
</template>

<script>
import flatpickr from 'flatpickr'

const DEFAULT_CONFIG = {
  multipleSeparator: ' , ',
  rangeSeparator: ' to ',
}

export default {
  props: {
    altFormat: {
      type: String,
      default: null,
    },
    disableDates: {
      type: Array,
      default: () => [],
    },
    enableDates: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: 'Y-m-d', // https://flatpickr.js.org/formatting/#date-formatting-tokens
    },
    label: {
      type: String,
      required: true,
    },
    maxDate: {
      type: [String, Date],
      default: null,
    },
    minDate: {
      type: [String, Date],
      default: null,
    },
    mode: {
      type: String,
      default: 'single',
      validator: value => ['single', 'multiple', 'range'].includes(value),
    },
    monthSelectorType: {
      type: String,
      default: 'static',
      validator: value => ['dropdown', 'static'].includes(value),
    },
    shorthandCurrentMonth: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Date, Array],
      default: null,
    },
  },
  data () {
    return {
      picker: null,
    }
  },
  computed: {
    pickerConfiguration () {
      return {
        allowInput: true,
        altFormat: this.altFormat,
        altInput: !!this.altFormat,
        conjunction: DEFAULT_CONFIG.multipleSeparator,
        dateFormat: this.format,
        disable: this.disableDates,
        enable: this.enableDates,
        locale: { rangeSeparator: DEFAULT_CONFIG.rangeSeparator },
        maxDate: this.maxDate,
        minDate: this.minDate,
        mode: this.mode,
        monthSelectorType: this.monthSelectorType,
        onChange: this.handleOnChange,
        shorthandCurrentMonth: this.shorthandCurrentMonth,
        // Terrible hack until component is ready in MDS V3
        prevArrow: `
          <button class="mds-button___radar mds-button--flat___radar">
           <
          </button>
        `,
        nextArrow: `
          <button class="mds-button___radar mds-button--flat___radar">
           >
          </button>
        `,
      }
    },
    formattedValue () {
      if (this.mode === 'multiple') {
        return this.value.join(DEFAULT_CONFIG.multipleSeparator)
      }
      if (this.mode === 'range') {
        return this.value.join(DEFAULT_CONFIG.rangeSeparator)
      }
      return this.value
    },
  },
  watch: {
    pickerConfiguration: {
      deep: true,
      handler (newConfig) {
        this.picker.set(newConfig)
      },
    },
  },
  mounted () {
    if (this.picker) return

    this.picker = flatpickr(this.$refs['picker'], this.pickerConfiguration)
  },
  beforeDestroy () {
    if (this.picker) {
      this.picker.destroy()
      this.picker = null
    }
  },
  methods: {
    handleOnChange (selectedDates) {
      const formatedDates = selectedDates.map(date => {
        return flatpickr.formatDate(date, this.format)
      })
      this.mode === 'single'
        ? this.$emit('input', formatedDates[0])
        : this.$emit('input', formatedDates)
    },
  },
}
</script>

<style lang="scss">
@import '@mds/input/src/input.scss';
@import '@mds/label/src/label.scss';
@import url('~flatpickr/dist/flatpickr.min.css');

.flatpickr-calendar {
  @include mds-body-text-s();
  border-radius: $mds-border-radius-panel;
  box-shadow: $mds-box-shadow-drop;

  // Remove "caret"
  &::before,
  &::after {
    content: none;
  }
}

.flatpickr-current-month {
  @include mds-level-4-heading();
  color: $mds-text-color-primary;

  span.cur-month {
    font-weight: inherit;
  }

  .cur-month:hover,
  .numInputWrapper {
    background: none !important;
  }

  .flatpickr-monthDropdown-months {
    &:hover {
      background: none;
    }
  }
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: inherit !important;
}

.flatpickr-day {
  line-height: 39px !important;
}

span.flatpickr-weekday {
  @include mds-body-text-s($bold: true);
  color: $mds-text-color-primary;
}

.flatpickr-day {
  &:hover,
  &:focus {
    border-color: $mds-color-neutral-90;
    background: $mds-color-neutral-90;
  }
}

.flatpickr-day.today {
  border: $mds-border-control;

  &:hover,
  &:focus {
    border-color: $mds-color-neutral-50;
    background: $mds-color-neutral-50;
  }
}

.flatpickr-day.inRange {
  border-color: #cce4f6 !important;
  background-color: #cce4f6 !important;
  box-shadow: -5px 0 0 #cce4f6, 5px 0 0 #cce4f6 !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  @include mds-body-text-s($bold: true);
  background-color: $mds-interactive-color-primary-default !important;
  border-color: $mds-interactive-color-primary-default !important;
  box-shadow: none !important;

  &:hover,
  &:focus {
    background-color: $mds-interactive-color-primary-hover !important;
    border-color: $mds-interactive-color-primary-hover !important;
  }
}
</style>
